import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';

import useLogin from './hooks/useLogin';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { email, setEmail, password, setPassword, submit, message } = useLogin();

	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/dashboard'), [navigate]);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const handleContinue = () => {
		setIsLoading(true);

		setTimeout(() => {
			setSignInPassword(true);
			setIsLoading(false);
		}, 1000);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			// className={classNames({ 'bg-warning': !singUpStatus, 'bg-info': singUpStatus })}
		>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} name={'/pnbp-logo.png'} />
									</Link>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								{message && (
									<Alert isLight icon='Lock' isDismissible>
										<div className='row'>
											<div className='col-12'>
												<strong>{message}</strong>
											</div>
										</div>
									</Alert>
								)}

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='loginUsername'
											isFloating
											label='Your email or username'
											className={classNames({
												'd-none': signInPassword,
											})}>
											<input
												type='text'
												value={email}
												onChange={(val) => setEmail(val.target.value)}
												className='form-control'
											/>
										</FormGroup>
										{signInPassword && (
											<div className='text-center h4 mb-3 fw-bold'>
												{!message ? `Hi, ${email}.` : null}
											</div>
										)}
										<FormGroup
											id='loginPassword'
											isFloating
											label='Password'
											className={classNames({
												'd-none': !signInPassword,
											})}>
											<input
												type='password'
												value={password}
												onChange={(val) => setPassword(val.target.value)}
												className='form-control'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										{!signInPassword ? (
											<Button
												color='warning'
												className='w-100 py-3'
												// isDisable={!formik.values.loginUsername}
												onClick={handleContinue}>
												{isLoading && <Spinner isSmall inButton isGrow />}
												Continue
											</Button>
										) : (
											<Button
												color='warning'
												className='w-100 py-3'
												onClick={submit}>
												Login
											</Button>
										)}
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
