import { baseURL } from '../utils/API';

// const baseUrl = 'https://pnbp-api.rke.rri.co.id/';
// const baseUrl = 'https://rridevpnbp.gocoding.web.id/';
const baseUrl = 
	// 'http://54.169.163.30:3001/'
	'https://pnbp-api.rri.co.id/'
	// 'http://localhost:3013/'

const endpoint = {
	GET_TOKEN: `${baseUrl}auth/get_token`,
	GET_TEST: `${baseUrl}auth/get_data`,
	GET_PNBP_TYPE: `${baseUrl}masters/pnbp_type/get_data`,
	GET_PNBP_KlIEN: `${baseUrl}masters/konsumen/get_data`,
	GET_PNBP_CATEGORY_KlIEN: `${baseUrl}masters/client_category/get_data`,
	GET_PNBP_JASA_PENYIARAN: `${baseUrl}jasa_siaran/proses_penyiaran/get_data_by_detail`,
	GET_PNBP_SERVICE: `${baseUrl}service/get_data`,
	GET_REKAPITULASI_DATA: `${baseUrl}rekapitulasi/piutang/get_data`,
	GET_TIME_CATEGORY: `${baseUrl}masters/time_category/get_data`,
	GET_DELETE_JASA_SIARAN: `${baseUrl}jasa_siaran/proses_penyiaran/delete_jasa_siaran?id=`,
	GET_DELETE_SUB_MENU: `${baseUrl}settings/sub_menus/delete?id=`,
	GET_DELETE_MENU: `${baseUrl}settings/menus/delete?id=`,
	GET_DELETE_ROLE: `${baseUrl}settings/role/delete?id=`,
	GET_DELETE_FREKUENSI: `${baseUrl}masters/frekuensi/delete?id=`,
	GET_DELETE_SATUAN_KERJA: `${baseUrl}masters/satker/delete?id=`,
	GET_SATUAN_KERJA: `${baseUrl}masters/satker/get_data`,
	GET_SERVICE_TYPE: `${baseUrl}masters/service_type/get_data`,
	GET_FREKUENSI: `${baseUrl}masters/frekuensi/get_data`,
	GET_ROLE: `${baseUrl}settings/role/get_data`,
	GET_MENU: `${baseUrl}settings/menus/get_data`,
	GET_SUB_MENU: `${baseUrl}settings/sub_menus/get_data`,
	GET_LIST_KONTRAK: `${baseUrl}kontrak/kontrak_penyiaran/get_data`,
	GET_DELETE_LIST_KONTRAK: `${baseUrl}kontrak/kontrak_penyiaran/delete?id=`,
	GET_TIPE_SATUAN_KERJA: `${baseUrl}/masters/zone/get_data`,
	GET_TIPE_SATUAN_KERJA: `${baseUrl}/masters/zone/get_data`,
	GET_JADWAL_SIARAN: `${baseUrl}/jadwal_siaran/get_data`,
	GET_JADWAL_LOG_SIARAN: `${baseUrl}/jadwal_siaran/log_siaran`,
	GET_KEUANGAN_INVOICE: `${baseUrl}/keuangan/invoice/get_data`,
	GET_KEUANGAN_REKAPITULASI: `${baseUrl}/keuangan/rekapitulasi/get_data`,
	GET_KEUANGAN_PEMBAYARAN: `${baseUrl}/keuangan/pembayaran/get_data`,
	GET_DELETE_KEUANGAN_PEMBAYARAN: `${baseUrl}keuangan/pembayaran/delete?id=`,
	GET_DELETE_JASA_PRODUKSI_PROGRAM: `${baseUrl}produksi_program/delete?id=`,
	GET_DELETE_JASA_DIGITALISASI_PENYIARAN: `${baseUrl}digitalisasi_penyiaran/delete_data?id=`,

	POST_LOGIN: `${baseUrl}auth/login/login_process`,
	POST_INSERT_KLIEN: `${baseUrl}masters/konsumen/insert`,
	POST_UPDATE_KLIEN: `${baseUrl}masters/konsumen/update`,
	POST_INSERT_JASA_PENYIARAN: `${baseUrl}jasa_siaran/proses_penyiaran/insert_detail_penyiaran`,
	POST_BIAYA_PENAYANGAN: `${baseUrl}jasa_siaran/proses_penyiaran/get_price`,
	POST_INSERT_FREKUENSI: `${baseUrl}masters/frekuensi/insert`,
	POST_INSERT_SATUAN_KERJA: `${baseUrl}masters/satker/insert`,
	POST_INSERT_ROLE: `${baseUrl}settings/role/insert`,
	POST_INSERT_MENU: `${baseUrl}settings/menus/insert`,
	POST_INSERT_SUB_MENU: `${baseUrl}settings/sub_menus/insert`,
	POST_INSERT_KONTRAK_JASA_PENYIARAN: `${baseUrl}kontrak/kontrak_penyiaran/insert`,
	POST_UPDATE_KONTRAK_JASA_PENYIARAN: `${baseUrl}kontrak/kontrak_penyiaran/update`,
	POST_UPLOAD_LAMPIRAN_JASA_PENYIARAN: `${baseUrl}jasa_siaran/lampiran/upload_lampiran`,
	POST_UPDATE_FREKUENSI: `${baseUrl}masters/frekuensi/update`,
	POST_UPDATE_SATUAN_KERJA: `${baseUrl}masters/satker/update`,
	POST_UPDATE_ROLE: `${baseUrl}settings/role/update`,
	POST_UPDATE_MENU: `${baseUrl}settings/menus/update`,
	POST_UPDATE_SUB_MENU: `${baseUrl}settings/sub_menus/update`,
	POST_PRINT_LAMPIRAN_JASA_PENYIARAN: `${baseUrl}generate/pdf_file/cetak_jasa_siaran`,
	POST_DASHBOARD_REPORT_NOMINAL_KONTRAK: `${baseUrl}dashboard/report_nominal_kontrak`,
	POST_DASHBOARD_REPORT_TOTAL_KONTRAK: `${baseUrl}dashboard/report_total_kontrak`,
	POST_DASHBOARD_REPORT_JASA_SIARAN: `${baseUrl}dashboard/report_jasa_siaran`,
	POST_DASHBOARD_REPORT_DATA_PNBP: `${baseUrl}dashboard/report_data_pnbp`,
	POST_DASHBOARD_REPORT_DATA_PNBP_MONTHLY: `${baseUrl}dashboard/report_data_pnbp_monthly`,

	POST_DASHBOARD_REPORT_DATA_PIUTANG: `${baseUrl}dashboard/report_data_piutang`,
	POST_STATUS_KONTRAK: `${baseUrl}kontrak/kontrak_penyiaran/update_status`,
	POST_UPDATE_JADWAL_SIAR: `${baseUrl}jadwal_siaran/update_status_siar`,
	POST_DETAIL_KONTRAK_JASA_PENYIARAN: `${baseUrl}kontrak/kontrak_penyiaran/get_data_by`,
	POST_KEUANGAN_INV_DETAIL: `${baseUrl}keuangan/pembayaran/detail`,
	POST_KEUANGAN_PEMBAYARAN_INV: `${baseUrl}keuangan/pembayaran/insert`,
	POST_GENERATE_BUKTI_SIAR: `${baseUrl}generate/bukti_siar_file/cetak`,
	POST_GENERATE_BUKTI_PEMBAYARAN: `${baseUrl}generate/bukti_pembayaran_file/cetak`,
	POST_UPDATE_KONTRAK: `${baseUrl}kontrak/kontrak_penyiaran/update_kontrak`,
	POST_CEK_NOMOR_KONTRAK: `${baseUrl}kontrak/kontrak_penyiaran/cek_nomor_kontrak`,
	POST_UPDATE_JASA_PENYIARAN: `${baseUrl}jasa_siaran/proses_penyiaran/update_penyiaran`,
	POST_EXPORT_EXCEL_KONTRAK: `${baseUrl}export/excel_kontrak`,
	POST_EXPORT_EXCEL_KEUANGAN_PEMBAYARAN: `${baseUrl}export/excel_pembayaran`,
	POST_EXPORT_EXCEL_KEUANGAN_INVOICE: `${baseUrl}export/excel_invoice`,
	POST_EXPORT_EXCEL_KEUANGAN_REKAPITULASI: `${baseUrl}export/excel_rekapitulasi`,
	POST_EXPORT_PDF_KONTRAK: `${baseUrl}export/pdf_kontrak`,
	POST_EXPORT_PDF_KEUANGAN_PEMBAYARAN: `${baseUrl}export/pdf_pembayaran`,
	POST_EXPORT_PDF_KEUANGAN_INVOICE: `${baseUrl}export/pdf_invoice`,
	POST_EXPORT_PDF_KEUANGAN_REKAPITULASI: `${baseUrl}export/pdf_rekapitulasi`,
	POST_DOWNLOAD_LAMPIRAN: `${baseUrl}jasa_siaran/lampiran/download_lampiran`,
	POST_GENERATE_INVOICE: `${baseUrl}generate/invoice_file/cetak`,
	POST_INSERT_PRODUKSI_PROGRAM: `${baseUrl}produksi_program/insert`,
	POST_UPDATE_PRODUKSI_PROGRAM: `${baseUrl}produksi_program/update`,
	POST_INSERT_DIGITALISASI: `${baseUrl}digitalisasi_penyiaran/insert_data`,
	POST_DETAIL_KONTRAK_PNBPFUNGSIONAL_STEP3: `${baseUrl}detail_kontrak/get_data`,
	POST_SERVICE_DETAIL_TYPE: `${baseUrl}masters/service_detail_type/get_data`,
	POST_SERVICE_DETAIL_TYPE: `${baseUrl}masters/service_detail_type/get_data`,
};

export default endpoint;
