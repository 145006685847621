import React, { lazy } from 'react';
import Login from '../pages/auth/login/IndexPage';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/IndexPage')),
	SUMMARY: lazy(() => import('../pages/SummaryPage')),
};

const PNBPFUNGSIONAL = {
	LISTKONTRAK: lazy(() => import('../pages/pnbp_fungsional/list_kontrak/IndexPage')),
	CREATEKONTRAK: lazy(() => import('../pages/pnbp_fungsional/create_kontrak/IndexPage')),
};

const PNBPUMUM = {
	LISTKONTRAK: lazy(() => import('../pages/pnbp_umum/list_kontrak/IndexPage')),
	CREATEKONTRAK: lazy(() => import('../pages/pnbp_umum/create_kontrak/IndexPage')),
	// EDITKONTRAK: lazy(() => import('../pages/pnbp_umum/list_kontrak/EditPage')),
};

const KEUANGAN = {
	REKAPITULASI: lazy(() => import('../pages/keuangan/rekapitulasi/IndexPage')),
	INVOICE: lazy(() => import('../pages/keuangan/invoice/IndexPage')),
	PEMBAYARAN: lazy(() => import('../pages/keuangan/pembayaran/IndexPage')),
	CREATEPEMBAYARAN: lazy(() => import('../pages/keuangan/pembayaran/CreatePembayaran')),
	REKAPITULASIPIUTANG: lazy(() => import('../pages/keuangan/rekapitulasi_piutang/IndexPage')),
};

const MASTERDATA = {
	MANAJEMENUSER: lazy(() => import('../pages/master_data/manajemen_user/IndexPage')),
	HAKAKSES: lazy(() => import('../pages/master_data/hak_akses/IndexPage')),
	SATUANKERJA: lazy(() => import('../pages/master_data/satuan_kerja/IndexPage')),
	TARIF: lazy(() => import('../pages/master_data/tarif/IndexPage')),
	JENISPNBP: lazy(() => import('../pages/master_data/jenis_pnbp/IndexPage')),
	TIPEPENYIARAN: lazy(() => import('../pages/master_data/tipe_penyiaran/IndexPage')),
	KONSUMEN: lazy(() => import('../pages/master_data/konsumen/IndexPage')),
	FREKUENSI: lazy(() => import('../pages/master_data/frekuensi/IndexPage')),
	ROLE: lazy(() => import('../pages/master_data/role/IndexPage')),
	MENU: lazy(() => import('../pages/master_data/menu/IndexPage')),
	SUBMENU: lazy(() => import('../pages/master_data/sub_menu/IndexPage')),
};

const ALL = {
	DATAALOKASISIARAN: lazy(() => import('../pages/data_alokasi_siaran/IndexPage')),
	DATAKLIEN: lazy(() => import('../pages/data_klien/IndexPage')),
	DATAPIUTANG: lazy(() => import('../pages/data_piutang/IndexPage')),
	REKAPITULASIDATA: lazy(() => import('../pages/rekapitulasi_data/IndexPage')),
	JADWALSIARAN: lazy(() => import('../pages/jadwal_siaran/IndexPage')),
	LOGSIARAN: lazy(() => import('../pages/log_siaran/IndexPage')),
};

const AUTH = {
	DASHBOARD: lazy(() => import('../pages/dashboard/IndexPage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: 'dashboard',
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	/**
	 * PNBP FUNGSIONAL
	 */
	{
		path: 'pnbp-fungsional/kontrak',
		element: <PNBPFUNGSIONAL.LISTKONTRAK />,
		exact: true,
	},
	{
		path: 'pnbp-fungsional/kontrak/create',
		element: <PNBPFUNGSIONAL.CREATEKONTRAK />,
		exact: true,
	},
	/**
	 * PNBP UMUM
	 */
	{
		path: 'pnbp-umum/kontrak',
		element: <PNBPUMUM.LISTKONTRAK />,
		exact: true,
	},
	{
		path: 'pnbp-umum/kontrak/create',
		element: <PNBPUMUM.CREATEKONTRAK />,
		exact: true,
	},
	// {
	// 	path: 'pnbp-umum/kontrak/edit',
	// 	element: <PNBPUMUM.EDITKONTRAK />,
	// 	exact: true,
	// },
	/**
	 * ALL PAGE
	 */
	{
		path: 'data-alokasi-siaran',
		element: <ALL.DATAALOKASISIARAN />,
		exact: true,
	},
	{
		path: 'data-klien',
		element: <ALL.DATAKLIEN />,
		exact: true,
	},
	{
		path: 'data-piutang',
		element: <ALL.DATAPIUTANG />,
		exact: true,
	},
	{
		path: 'rekapitulasi-data',
		element: <ALL.REKAPITULASIDATA />,
		exact: true,
	},
	{
		path: 'jadwal-siaran',
		element: <ALL.JADWALSIARAN />,
		exact: true,
	},
	{
		path: 'log-siaran',
		element: <ALL.LOGSIARAN />,
		exact: true,
	},

	/**
	 * Keuangan
	 */
	{
		path: 'keuangan/rekapitulasi',
		element: <KEUANGAN.REKAPITULASI />,
		exact: true,
	},
	{
		path: 'keuangan/invoice',
		element: <KEUANGAN.INVOICE />,
		exact: true,
	},
	{
		path: 'keuangan/pembayaran',
		element: <KEUANGAN.PEMBAYARAN />,
		exact: true,
	},
	{
		path: 'keuangan/pembayaran/create',
		element: <KEUANGAN.CREATEPEMBAYARAN />,
		exact: true,
	},
	{
		path: 'keuangan/rekapitulasi-piutang',
		element: <KEUANGAN.REKAPITULASIPIUTANG />,
		exact: true,
	},

	/**
	 * Master Data
	 */
	{
		path: 'master-data/manajemen-user',
		element: <MASTERDATA.MANAJEMENUSER />,
		exact: true,
	},
	{
		path: 'master-data/hak-akses',
		element: <MASTERDATA.HAKAKSES />,
		exact: true,
	},
	{
		path: 'master-data/satuan-kerja',
		element: <MASTERDATA.SATUANKERJA />,
		exact: true,
	},
	{
		path: 'master-data/tarif',
		element: <MASTERDATA.TARIF />,
		exact: true,
	},
	{
		path: 'master-data/jenis-pnbp',
		element: <MASTERDATA.JENISPNBP />,
		exact: true,
	},
	{
		path: 'master-data/tipe-penyiaran',
		element: <MASTERDATA.TIPEPENYIARAN />,
		exact: true,
	},
	{
		path: 'master-data/konsumen',
		element: <MASTERDATA.KONSUMEN />,
		exact: true,
	},
	{
		path: 'master-data/frekuensi',
		element: <MASTERDATA.FREKUENSI />,
		exact: true,
	},
	{
		path: 'master-data/role',
		element: <MASTERDATA.ROLE />,
		exact: true,
	},
	{
		path: 'master-data/menu',
		element: <MASTERDATA.MENU />,
		exact: true,
	},
	{
		path: 'master-data/sub-menu',
		element: <MASTERDATA.SUBMENU />,
		exact: true,
	},

	/**
	 * Auth Page
	 */
	{
		path: '/',
		element: <Login />,
		exact: true,
	},
	{
		path: '/',
		element: <Login isSignUp />,
		exact: true,
	},
	// ----------------END ROUTE NEW-----------------------

	/** ************************************************** */

	/**
	 * Auth Page & Profile
	 */
	// {
	// 	path: 'profile',
	// 	element: <AUTH.PROFILE />,
	// 	exact: true,
	// },
];
const contents = [...presentation];

export default contents;
