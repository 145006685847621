import { useEffect, useState } from 'react';
import endpoint from '../../../../constant/endpoint';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const useLogin = () => {
	// const [email, setEmail] = useState('lpu.puspem@rri.go.id');
	// const [password, setPassword] = useState('LPPRRI45');

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const [token, setToken] = useState(null);
	const [expired, setExpired] = useState(null);

	const dispatch = useDispatch();

	const navigate = useNavigate();

	let options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: '',
		},
	};

	useEffect(() => {
		getToken();
	}, []);

	const getToken = async () => {
		const data = { username: email, password: password };

		fetch(endpoint.GET_TOKEN, {
			method: 'POST',
			headers: options.headers,
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				setToken(res.data.token);
				setExpired(res.data.expire_at);
			})
			.catch((error) => console.log(error));
	};

	const submit = async () => {
		options.headers.Authorization = `Bearer ${token}`;

		const data = { email: email, password: password };

		fetch(endpoint.POST_LOGIN, {
			method: 'POST',
			headers: options.headers,
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				dispatch({
					type: 'DATA_USER',
					data: res.data,
				});
				if (res.code === 200) {
					localStorage.setItem('_menus', JSON.stringify(res.data.menus));
					navigate('/dashboard');
					dispatch({
						type: 'DATA_USER',
						data: res.data,
					});
					return;
				}

				setMessage(res.message);
			})
			.catch((error) => console.log(error));
	};

	return { email, setEmail, password, setPassword, submit, message };
};

export default useLogin;
