import Axios from 'axios';

// development
// export const baseURL = 'https://apidevpnbp.rri.co.id';
export const baseURL = 'https://pnbp-api.rri.co.id'; //'https://gocoding.web.id';

const API = Axios.create({
	baseURL: baseURL,
	headers: {
		'Content-Type': 'application/json',
	},
	data: {},
	timeout: 5000,
});

API.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		if (response.data.status === 0) {
			return Promise.reject(response.data.message);
		}

		return response;
	},

	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		return Promise.reject(error);
	},
);

export default API;
