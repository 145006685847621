const INITIAL_STATE = {
	dataUser: {
		auth: {
			token: '',
			expire_at: 0,
		},
		user: {
			id: '0',
			role_id: '0',
			position_id: null,
			work_unit_id: '0',
			work_unit: '',
			zone_id: '0',
			zone: '',
			zone_type: '0',
			code: '',
			latitude: '',
			longitude: '',
			address: '',
			nik: '',
			npwp: '',
			phone: '',
			name: '',
			photo: null,
			name_changed: null,
			email: '',
			last_login: null,
			last_ip: null,
			banned: '0',
			ban_message: null,
			active: null,
			timezone: null,
			language: null,
		},
		menus: {
			dashboard: {
				id: 'dashboard',
				text: 'Dashboard',
				path: '/dashboard',
				icon: 'Dashboard',
				subMenu: null,
			},
			pnbpFungsional: {
				id: 'pnbpFungsional',
				text: 'PNBP Fungsional',
				path: 'pnbp-fungsional/list-kontrak',
				icon: 'Article',
				subMenu: {
					listKontrak: {
						id: 'infoPks',
						text: 'List Kontrak',
						path: '#',
						icon: 'ViewDay',
					},
					tambahKontrak: {
						id: 'pks',
						text: 'Tambah Kontrak',
						path: '#',
						icon: 'ViewDay',
					},
				},
			},
			pnbpUmum: {
				id: 'pnbpUmum',
				text: 'PNBP Umum',
				path: 'pnbp-umum/list-kontrak',
				icon: 'Article',
				subMenu: {
					listKontrak: {
						id: 'infoPks',
						text: 'List Kontrak',
						path: '#',
						icon: 'ViewDay',
					},
					tambahKontrak: {
						id: 'pks',
						text: 'Tambah Kontrak',
						path: '#',
						icon: 'ViewDay',
					},
				},
			},
			dataAlokasiSiaran: {
				id: 'dataAlokasiSiaran',
				text: 'Data Alokasi Siaran',
				path: '/data-alokasi-siaran',
				icon: 'Article',
				subMenu: null,
			},
			dataKlien: {
				id: 'dataKlien',
				text: 'Data Klien',
				path: '/data-klien',
				icon: 'Article',
				subMenu: null,
			},
			rekapitulasiData: {
				id: 'rekapitulasiData',
				text: 'Rekapitulasi Data',
				path: '/rekapitulasi-data',
				icon: 'Article',
				subMenu: null,
			},
			dataPiutang: {
				id: 'dataPiutang',
				text: 'Data Piutang',
				path: '/data-piutang',
				icon: 'Article',
				subMenu: null,
			},
		},
	},
};

const users = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'DATA_USER':
			return {
				...state,
				dataUser: action.data,
			};
		default:
			return state;
	}
};

export default users;
