import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
	name?: string;
}
const Logo: FC<ILogoProps> = ({ width, height, name }) => {
	return (
		<img style={{width: width}}  src={window.location.origin + name} alt="" />
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
